import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Link } from 'gatsby'
import { Box, Button } from '@mui/material'

function Li({ children }: any) {
    return <li style={{ marginTop: 4 }}>{children}</li>
}

export default function BasicCriteriasContent() {
    return (
        <Box sx={{ background: '#D9D9D9', py: 2 }}>
            <Typography variant="body1" fontWeight="bold" textAlign="center" sx={{ fontSize: 18 }}>
                De basale kriterier:
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 18 }}>
                <ol type="1">
                    <Li>
                        Du har bopæl til rådighed i Danmark, eller er på anden vis skattepligtig til
                        DK
                    </Li>
                    <Li>
                        Lønindkomsten skal være erhvervet ved personligt arbejde i tjenesteforhold*
                    </Li>
                    <Li>
                        Under ophold i Danmark må der ikke arbejdes, bortset fra eventuel nødvendigt
                        arbejde, såsom afrapportering.
                    </Li>
                </ol>
            </Typography>
        </Box>
    )
}
