import ThomasJpeg from '../assets/thomas.jpeg'
import React, { useEffect, useState } from 'react'
import { Button, Space } from 'antd'
import { graphql } from 'gatsby'
import Container from '../components/Landing/Container'
import { EditOutlined, PaperClipOutlined, FileDoneOutlined } from '@ant-design/icons'
import { BookDemoModal } from '../components/Landing/BookDemoModal'
import FeatureCarousel from '../components/Landing/FeatureCarousel'
import { isMobile } from 'react-device-detect'
import { getStoredAuthToken } from '../utils/auth-token'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography,
} from '@mui/material'
import SignupBelt from '../components/Landing/SignupBelt'
import EmpathyContent from '../components/Landing/EmpathyContent'
import BasicCriteriasContent from '../components/Landing/BasicCriteriasContent'
import BackNavTop from '../components/PageWrappers/BackNavTop'
import RunningCriteriasContent from '../components/Landing/RunningCriteriasContent'

function buildSignupButton(authToken) {
    return (
        <a href={!!authToken ? '/app' : '/signup'}>
            <Button
                className="button-shadow"
                shape="round"
                type="primary"
                style={
                    !!authToken
                        ? {}
                        : {
                              borderColor: '#7EAC55',
                              backgroundColor: '#7EAC55',
                          }
                }
            >
                {!!authToken ? 'Login' : 'Prøv gratis helt uforpligtende'}
            </Button>
        </a>
    )
}

function LandingPage({ data }) {
    const [authToken] = useState(getStoredAuthToken())
    const [signupButton, setSignupButton] = useState(buildSignupButton(authToken))

    useEffect(() => {
        setSignupButton(buildSignupButton(authToken))
    }, [authToken])

    return (
        <Container setCookieValue={() => {}} pathName="index">
            <SEO title="Kriterier til ll§33a" />
            <Box sx={{ width: 'min(100%, 800px)', m: 'auto' }}>
                <BackNavTop
                    navTo="/"
                    width="min(100%, 800px)"
                    top="56px"
                    backgroundColor={'#f0f2f5'}
                />
            </Box>
            <Box sx={{ height: 30 }}></Box>
            <Box
                sx={{
                    my: 5,
                    mx: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid container sx={{ maxWidth: '800px' }} spacing={2}>
                    <Grid xs={12} item>
                        <Typography variant="h6" fontWeight="bold">
                            For at kunne bruge LL §33A er der nogle betingelser, der skal være
                            opfyldt.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <BasicCriteriasContent />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            *Bemærk, i henhold til ny principiel dom fra Østre Landsret Sag
                            BS-24472/2021-OLR, skal tjenesteforhold i udlandet være begrundet i
                            arbejdsgiverens forhold:
                            <br />
                            <br />
                            <Typography component="span" fontStyle="italic">
                                “...tilfælde, hvor en dansk lønmodtager, som har bevaret sin fulde
                                skattepligt til Danmark, af egen drift og uafhængigt af
                                arbejdsgiveren vælger at bosætte sig i udlandet, uden at det
                                pågældende land har sammenhæng med arbejdsgiverens forhold, falder
                                derimod uden for bestemmelsen.“
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <SignupBelt />
        </Container>
    )
}

export default LandingPage
